+function($){

    $( init );

    function init() {
        initializeLoader();
        initializeHeader();
        //TODO: el display none del texto original hay que hacerlo en DOMLoad
        // y el resto en window load
        hideTitles();
        $( window ).load( initializeTitleAnimations );
        // Sólo cuando se hayan cargado las imágenes
        if ( ! isIos() ) {
            $( window ).load( initializeProjectPreviews );
        }
    }
    
    function isIos() {
        var isIos = /(iPhone|iPad)/i.test(navigator.userAgent);
        var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);

        return ( isIos && isSafari );
    }

    function initializeLoader() {
        var loader = $('#loader');
        loader.css({
            opacity: '0'
        });
        setTimeout(function(){
            loader.css('display', 'none');
        }, 600);
    }

    function initializeHeader() {
        var $header = $( '.jumbotron' );

        $( window ).resize( setHeight );
        setHeight();

        function setHeight() {
            $header.height( $( window ).height() );
        }
    }

    function hideTitles() {
        var $titles = $( '.meta-info p' );
        $titles.css('visibility', 'hidden');
    }

    function initializeTitleAnimations() {
        var boxesSelector = '.meta-info:not(.js-animation-end)';
        var $boxes = $( boxesSelector );
        var animating = false;
        var scrollTop;

        $( window ).on( 'scroll', scroll );
        scroll();

        function scroll() {
            if ( ! animating ) {
                animating = true;
                requestAnimationFrame( frame );
            }
        }

        function frame() {
            // Posición en la que se empieza a animar
            scrollTop = $( window ).scrollTop() + ( $( window ).height() * 0.75 );
            // Comprobar para cada caja
            $boxes.each( checkBoxAnimation );
            // Flag para el debounce del evento de scroll
            animating = false;
        }

        function checkBoxAnimation() {
            var $box = $( this );

            // Saltamos las cajas ya animadas
            if ( $box.hasClass( '.js-animation-end' ) ) {
                return;
            }
            
            // Saltamos si no ha llegado a la posición
            if ( $box.offset().top > scrollTop ) {
                return;
            }

            // Títulos dentro de la caja
            var $titles = $box.find( 'p' );
            $titles.each( function () {
                // Lanzamos animación
                initializeTitle.call( $( this ) );
            });

            // Marcamos la caja como ya animada
            $box.addClass( 'js-animation-end' );

            // Actualizamos la lista de cajas
            $boxes = $( boxesSelector );

            // Si la lista está vacía, quitamos el evento scroll
            if ( $boxes.length == 0) {
                $( window ).off( 'scroll', scroll );
            }
        }
    }

    function initializeTitle() {
        var margin = 7;

        // Bloque de título original
        var $title = $( this );

        var width  = $title.outerWidth(),
            height = $title.outerHeight(),
            top    = $title.position().top,
            left   = $title.position().left,
            backgroundColor = $title.css( 'backgroundColor' ),
            color = $title.css( 'color' );
        
        // Caja de texto original
        var $text  = $title.find('span');

        if ( $text.length == 0 ) {
            console.warn( 'Las cajas de título deben tener un <p> y un <span>' );
            return;
        }

        var textTop = $text.position().top;
        var textLeft = $text.position().left;
        var textWidth = $text.width();

        // Bloque de divs que reemplazan al <p> del título
        var $t = $('<div class="c0"><div class="c1"></div><div class="c2"></div><div class="c3"></div></div>');

        // Contenedor y borde
        var $c0 = $t;
        $c0.css({
            top:    top  + 'px',
            left:   left + 'px',
            width:  margin  + 'px',
            height: 0 + 'px',
            backgroundColor: backgroundColor
        });
        // Mantener clases responsive
        if ( $title.hasClass('hidden-cs-down') ) $c0.addClass('hidden-cs-down');
        if ( $title.hasClass('hidden-cs-up') ) $c0.addClass('hidden-cs-up');

        // Máscara color fondo
        var $c1 = $t.find( '.c1' );
        $c1.css({
            top:    margin  + 'px',
            left:   margin + 'px',
            width:  (width - (margin * 2))  + 'px',
            height: (height - (margin * 2)) + 'px',
            backgroundColor: color
        });

        // Máscara color caja
        var $c2 = $t.find( '.c2' );
        $c2.css({
            top:    margin  + 'px',
            left:   margin + 'px',
            width:  0  + 'px',
            height: (height - (margin * 2)) + 'px',
            backgroundColor: backgroundColor
        });

        // Texto
        var $c3 = $t.find( '.c3' );
        $c3.html( $title.text() );
        $c3.css({
            width: textWidth + 'px',
            transform: 'translate3d('+textLeft+'px,'+(textTop+height)+'px,0)',
            color: color
        });

        setTimeout( function() {
            animate();
            setTimeout( function() {
                $( window ).resize( resize );
                $c0.addClass('js-animation-end');
            }, 900 );
        }, 100 );

        

        function animate() {
            $c0.css({
                width:  width  + 'px',
                height: height + 'px'
            });

            // Máscara color caja
            var $c2 = $t.find( '.c2' );
            $c2.css({
                width:  (width - (margin * 2))  + 'px'
            });

            // Texto
            var $c3 = $t.find( '.c3' );
            $c3.html( $title.text() );
            $c3.css({
                transform: 'translate3d('+textLeft+'px,'+textTop+'px,0)',
                color: color
            });
        }
        
        function resize() {
            var width  = $title.outerWidth(),
                height = $title.outerHeight(),
                top    = $title.position().top,
                left   = $title.position().left;
            
            var textTop = $text.position().top;
            var textLeft = $text.position().left;
            var textWidth = $text.width();

            $c0.css({
                top:    top  + 'px',
                left:   left + 'px',
                width:  width  + 'px',
                height: height + 'px'
            });
            $c1.css({
                top:    margin  + 'px',
                left:   margin + 'px',
                width:  (width - (margin * 2))  + 'px',
                height: (height - (margin * 2)) + 'px',
                backgroundColor: color
            });
            $c2.css({
                top:    margin  + 'px',
                left:   margin + 'px',
                width:  (width - (margin * 2))  + 'px',
                height: (height - (margin * 2)) + 'px'
            });
            $c3.css({
                width: textWidth + 'px',
                transform: 'translate3d('+textLeft+'px,'+textTop+'px,0)'
            });
        }

        //$title.parent().append( $t );
        $title.css( 'visibility', 'hidden');
        $title.parent().append( $t );
    }

    function initializeProjectPreviews() {
        var $previews = $( '.project-preview' );
        var $window   = $( window );
        
        var items     = [];
        var scrollTop = $window.scrollTop();
        var isAnimating = false;
        var windowHeight = $window.height();
        
        initPreviews();        
        $( window )
            .on( 'scroll', updateScroll )
            .on( 'resize', initPreviews );
        updateScroll();

        function initPreviews() {
            windowHeight = $window.height();
            items = [];
            $previews.each( function() {
                var $parent = $( this );
                var $this = $parent.find( 'img' );
                $this.css( 'transition', 'transform linear 100ms');
                items.push({
                    $item: $this,
                    top: $this.offset().top,
                    height: $this.height(),
                    containerHeight: $parent.height(),
                    currentTop: $this.offset().top
                });
            });
        }

        function updateScroll( e ) {
            scrollTop = $window.scrollTop();
            if ( ! isAnimating ) {
                isAnimating = true;
                requestAnimationFrame( updatePosition );
            }
        }

        function updatePosition( e ) {
            var middle = $window.scrollTop() + Math.round( windowHeight * 0.35 );
            var i, length = items.length;
            var item, $item, top, height, containerHeight, bottom, containerBottom, newTop;

            for ( var i = 0; i < length; i++ ) {
                item            = items[i];
                $item           = item.$item;
                top             = item.top;
                height          = item.height;
                containerHeight = item.containerHeight;
                bottom          = top + height;
                containerBottom = top + containerHeight;
                newTop = 0;

                // No animamos si la captura es menor que su contenedor
                if ( height <= containerHeight ) {
                    continue;
                }

                // antes de empezar a animar
                if ( top >= middle ) {
                    newTop = 0;
                }
                // espacio en el que animamos
                else if ( top < middle && containerBottom > middle ) {
                    newTop = Math.max( - height + containerHeight, (top - middle)*1 );
                }
                // después de animar
                else if ( containerBottom <= middle) {
                    newTop = Math.max( - height + containerHeight, (top - containerBottom)*1 );
                }

                // Actualizamos sólo si 
                if ( newTop !== item.currentTop ) {
                    item.currentTop = newTop;
                    $item.css( 'transform', 'translate3d(0,' + newTop + 'px,0)' );
                }
                
            }
            isAnimating = false;
        }
    }

}(jQuery);
